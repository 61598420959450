import React, { useEffect, useState } from 'react'
import Idos from './Idos';
import { gql, useQuery } from '@apollo/client';
import useIsAdmin from 'src/utils/isAdmin';
import { useWallet } from 'use-wallet';
import BigNumber from 'bignumber.js';
import multicalls from '../multicall';
import ido from '../ABI/ido.json';

const ALL_IDOS = gql`

query {
      allIdos{
        id
        productLogo
        productName
        price
        description
        tokenAddress
        contractAddress
        images
        vestingSchedule
        status
        listed
        metaData
        
      }
    }

`;

function Ido() {

  const isAdmin = useIsAdmin();

  const wallet = useWallet();

  const [dataLoaded, setdataLoaded] = useState("");

  const [filteredData, setfilteredData] = useState("");

  console.log(isAdmin, "isAdmin");

  const loadContractData = async (e) => {
    console.log(e, "eeeeeeeeeeeeeeeee");
    const array = dataLoaded ? dataLoaded : [];

    for (let i in e) {
      console.log(e[i], "loop");
      if (e[i].contractAddress) {
        const calls = [
          // Balance of token in the LP contract
          {
            address: e[i].contractAddress,
            name: 'tradeValue',
            params: [],
          },
          {
            address: e[i].contractAddress,
            name: "isSaleFunded",
            params: []
          },
          {
            address: e[i].contractAddress,
            name: "hasStarted",
            params: []
          },
          {
            address: e[i].contractAddress,
            name: "endDate"
          },
          {
            address: e[i].contractAddress,
            name: "startDate"
          },
          {
            address: e[i].contractAddress,
            name: "tokensLeft"
          },
          {
            address: e[i].contractAddress,
            name: "availableTokens"
          },
          {
            address: e[i].contractAddress,
            name: "tokensForSale"
          },
          {
            address: e[i].contractAddress,
            name: "isOpen"
          },
          {
            address: e[i].contractAddress,
            name: "individualMaximumAmount"
          },
          {
            address: e[i].contractAddress,
            name: "totalRaiseCost"
          }
        ]

        const [
          tradeValue,
          funded,
          started,
          endDate,
          startDate,
          tokenLeft,
          availableTokens,
          tokensForSale,
          isOpen,
          minimumPersonalAllocation, totalRaisedCost
        ] = await multicalls(ido, calls)

        console.log(new BigNumber(tradeValue).toNumber());
        // let obj = e[i];
        // console.log(obj);
        let obj = {}
        obj.tradeValue = new BigNumber(tradeValue).toNumber();
        obj.funded = funded[0];
        obj.started = started[0];
        obj.endDate = new BigNumber(endDate).toNumber();
        obj.startDate = new BigNumber(startDate).toNumber();
        obj.tokenLeft = new BigNumber(tokenLeft).toNumber();
        obj.availableTokens = new BigNumber(availableTokens).toNumber();
        obj.tokensForSale = new BigNumber(tokensForSale).toNumber();
        obj.totalRaise = tokensForSale - tokenLeft;
        obj.isOpen = isOpen[0];
        obj.minimumPersonalAllocation = new BigNumber(minimumPersonalAllocation).toNumber();
        obj.totalRaisedCost = new BigNumber(totalRaisedCost).toNumber();
        obj.contractAddress = e[i].contractAddress;
        obj.name = e[i].productName;
        obj.id = e[i].id;
        // return obj;
        array.push(obj);
        // console.log(dtata, "array");
      }
    }

    console.log(array, "array");
    await setdataLoaded(array);
    const filtereddata = await array.filter((e) => {
      if (!e.started || e.started && e.isOpen) {
        return e;
      }
    })
    console.log(filtereddata, "filterdata");
    await setfilteredData(filtereddata);
    return array;
  }





  const { data } = useQuery(ALL_IDOS);
  useEffect(async () => {

    if (data && isAdmin) {
      // setdata(dataQuery);
      await loadContractData(data.allIdos);
    }
    return () => {

    }
  }, [data, isAdmin])


  const handleRunning = async () => {
    const filtereddata = await dataLoaded.filter((e) => {
      if (!e.started || e.started && e.isOpen) {
        return e;
      }
    })
    console.log(filtereddata, "filterdata");
    await setfilteredData(filtereddata);
  }

  const handleExpired = async () => {
    const filtereddata = await dataLoaded.filter((e) => {
      console.log('sasasasass',filtereddata)
      if (e.started && !e.isOpen) {
        return e;
      }
    })
    console.log(filtereddata, "filterdata");
    await setfilteredData(filtereddata);
  }

  return (
    <div>

      <ul style={{ listStyle: "none", padding: "10px" }}>
        <li onClick={() => {
          handleRunning()
        }} style={{ display: "inline", padding: "10px", cursor: "pointer" }}>
          Upcoming Idos
        </li>
        <li onClick={() => {
          handleExpired()
        }} style={{ display: "inline", padding: "10px", cursor: "pointer" }}>
          Previous Idos
        </li>
      </ul>
      {filteredData && isAdmin && <Idos data={filteredData} />}
      {!isAdmin && <div>
        You Are not authorized
        <button onClick={() => { wallet.connect() }}>
          Connect
        </button>
      </div>}
    </div>
  )
}

export default Ido