import { gql, useQuery } from '@apollo/client';
import React from 'react'
import WhiteList from './WhiteList'


const ALL_WHITELIST = gql`
query {
    whiteLists{
        id
        metaData
        contractAddress
        ido
    }
    }
`;

function Whitelist() {

    const { data } = useQuery(ALL_WHITELIST);


    console.log(data);

    return (

        <div>
            {data && <WhiteList data={data.whiteLists} />}
        </div>
    )
}

export default Whitelist