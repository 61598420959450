/* import the ipfs-http-client library */
import { create } from 'ipfs-http-client';

/* Create an instance of the client */
export  const ipfsClient =  create('https://ipfs.infura.io:5001/api/v0')

 



export const uploadImageToIpfs = (file)=>{
    return ipfsClient.add(file)

}