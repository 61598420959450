import React from 'react'
import EditIdo from './EditIdo'
import { gql, useQuery } from '@apollo/client';
import useIsAdmin from 'src/utils/isAdmin';
import { useWallet } from 'use-wallet';


const ALL_IDOS = gql`

query IDO($id:String!) {
      ido(id:$id){
        id
        productLogo
        productName
        price
        description
        tokenAddress
        contractAddress
        images
        vestingSchedule
        status
        listed
        metaData
        
      }
    }

`;

function Editido() {
    console.log(window.location.pathname.split("/"), "hii");
    const id = window.location.pathname.split("/")[3];

    const wallet = useWallet();


    const isAdmin = useIsAdmin();

    const { data } = useQuery(ALL_IDOS, {
        variables: { id }
    })


    return (
        <div>
            {data && isAdmin && <EditIdo data={data} />}
            {!isAdmin && <div>
                You Are not authorized
                <button onClick={() => { wallet.connect() }}>
                    Connect
                </button>
            </div>}
        </div>
    )
}

export default Editido