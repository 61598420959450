import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import { create } from "ipfs-http-client";
import factorAbi from "../utils/idofactory.json";
import tokenAbi from "../utils/erc.json";

import {
  Card,
  Table,
  TextField,
  IconButton,
  Divider,
  InputAdornment,
  Stack,
  Input,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextareaAutosize,
} from "@mui/material";
import { LoadingButton, DatePicker } from "@mui/lab";

// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../components/_dashboard/user";
//
import USERLIST from "../../_mocks_/user";
import { ipfsClient, uploadImageToIpfs } from "../../ipfs";
import { image } from "faker";
import Web3 from "web3";
import { useWallet } from "use-wallet";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toWei } from "../../utils/index";
import { toast } from "react-toastify";
import useIsAdmin from "src/utils/isAdmin";
import moment from "moment";

const CREATE_IDO = gql`
  mutation CreateIdo(
    $productName: String!
    $productLogo: String
    $description: String
    $price: String!
    $tokenAddress: String!
    $images: String
    $contractAddress: String
    $vestingSchedule: String
    $status: String
    $listed: Boolean
    $metaData: String
  ) {
    createIdo(
      ido: {
        productName: $productName
        productLogo: $productLogo
        description: $description
        price: $price
        tokenAddress: $tokenAddress
        images: $images
        contractAddress: $contractAddress
        vestingSchedule: $vestingSchedule
        status: $status
        listed: $listed
        metaData: $metaData
      }
    ) {
      id
      price
      productName
      description
    }
  }
`;

const ImageInput = ({ title, src, onChange, height, width }) => {
  const [image, setImage] = useState(src);
  const [isLoading, setLoading] = useState(false);

  return (
    <div>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {title}
      </Typography>

      {isLoading ? (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <>
          {image ? <img src={image} height={height} width={width} /> : null}
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={async (e) => {
              // const reader = new FileReader(), files = e.target.files;
              const file = e.target.files[0];
              setLoading(true);
              const { path } = await uploadImageToIpfs(file);
              console.log(path);
              // setImage(file);
              console.log(file);
              onChange(path);
              setImage(URL.createObjectURL(file));
              setLoading(false);
              // reader.onload = function () {
              //   // setImage(img);
              //   // setImage(reader.result);
              //   // onChange(reader.result);
              // };
              // reader.readAsDataURL(files[0]);
              // console.log(reader.result);
              // reader.readAsDataURL(files[0]);
              // console.log(reader.readAsDataURL(files[0]));
              // console.log(URL.createObjectURL(file));
            }}
          />
        </>
      )}
    </div>
  );
};

const DescriptionsInput = ({
  onHeadingChange,
  onContentChange,
  onImageChange,
  title,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [image, setImage] = useState();
  return (
    <>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {title}
      </Typography>

      <TextField
        fullWidth
        // minRows={1}
        label="Enter Title"
        onChange={(e) => {
          onHeadingChange(e.target.value);
        }}
      />

      <TextField
        fullWidth
        style={{ marginTop: 10 }}
        mt={10}
        minRows={10}
        label="Enter Content"
        onChange={(e) => {
          onContentChange(e.target.value);
        }}
      />

      {image ? <img src={image} height="200px" width="200px" /> : null}
      <input
        type="file"
        style={{ marginTop: 10, marginBottom: 20 }}
        accept="image/png, image/jpeg"
        onChange={async (e) => {
          const file = e.target.files[0];
          setImage(URL.createObjectURL(file));
          // setLoading(true);
          // // const { path } = await uploadImageToIpfs(file);
          // setLoading(false);
          // onImageChange(file);
          // const reader = new FileReader(), files = e.target.files;
          // const file = e.target.files[0];
          setLoading(true);
          const { path } = await uploadImageToIpfs(file);
          onImageChange(path);
          setLoading(false);
          // reader.onload = function () {
          //   // setImage(img);
          //   // setImage(reader.result);
          //   // onImageChange(reader.result);
          // };
          // reader.readAsDataURL(files[0]);
          // console.log(reader.result);
        }}
      />
    </>
  );
};
export default function CreateIdo() {
  const isAdmin = useIsAdmin();

  const [createIdo, { dataIdo, loadingIdo, errorIdo }] =
    useMutation(CREATE_IDO);

  const [presaleInfo, setPresaleInfo] = useState({
    startDate: "",
    endDate: "",
    pricePerToken: "",
    investToken: "",
    tokensForSale: "",
    maxContribute: "",
    minContribute: "",
    minimumRaise: "",
    feePercent: "",
  });

  useEffect(() => {
    console.log(document.getElementById("startDate"), "elemet");
    if (document.getElementById("startDate")) {
      document.getElementById("startDate").min =
        moment().format("YYYY-MM-DDThh:mm");
    }
    if (document.getElementById("endDate")) {
      document.getElementById("endDate").min = presaleInfo?.startDate
        ? moment(presaleInfo?.startDate).format("YYYY-MM-DDThh:mm")
        : moment().format("YYYY-MM-DDThh:mm");
    }
    return () => {};
  }, [
    isAdmin,
    document.getElementById("startDate"),
    document.getElementById("endDate"),
    presaleInfo?.startDate,
  ]);

  const [inCreation, setinCreation] = useState(false);
  // const [createIdo, { dataIdo, loadingIdo, errorIdo }] =
  //   useMutation(CREATE_IDO);

  const renderButton = () => {
    if (wallet.status === "connected") {
      return (
        <LoadingButton
          onClick={() => {
            if (!inCreation) {
              UploadFile();
            } else {
              toast("Ido Creation Process in Between");
            }
          }}
          fullWidth
          size="large"
          type="submit"
          variant={inCreation ? "disabled" : "contained"}
        >
          {inCreation ? "Loading..." : "Create Ido"}
        </LoadingButton>
      );
    }
    // if (wallet.status === "connected") {
    //   return (
    //     <LoadingButton fullWidth size="large" type="submit" variant="contained">
    //       Loading...
    //     </LoadingButton>
    //   )
    // }
    else {
      return (
        <LoadingButton
          onClick={() => {
            wallet.connect();
          }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Unlock Wallet
        </LoadingButton>
      );
    }
  };

  const shouldBeNumber = (number) => {
    var reg = new RegExp("^[0-9]*$");
    if (number.test(reg) == true) {
      return true;
    }
    return false;
  };

  //meta data///
  const wallet = useWallet();
  const [projectMetadata, setProjectMetadata] = useState({
    name: "",
    logo: "",
    headerImage: "",
    tokenAddress: "",
    socialMedias: {
      facebook: "",
      twitter: "",
      medium: "",
      telegram: "",
    },
  });

  const [descriptions, setDescriptions] = useState({
    product: {
      heading: "",
      description: "",
      image: "",
    },

    problem: {
      heading: "",
      description: "",
      image: "",
    },

    solution: {
      heading: "",
      description: "",
      image: "",
    },

    businessModal: {
      heading: "",
      description: "",
      image: "",
    },

    market: {
      heading: "",
      description: "",
      image: "",
    },
    investor: {
      heading: "",
      description: "",
      image: "",
    },
    team: {
      heading: "",
      description: "",
      image: "",
    },

    utility: {
      heading: "",
      description: "",
      image: "",
    },
  });

  const insertZero = (value, length) => {
    const intialValue = "000000000000000000";
    // const length = value.length;
    const finalstring = intialValue.slice(length);
    console.log(finalstring, value.concat(finalstring), "final");
    return value.concat(finalstring);
  };

  const handleAmount = async (amount) => {
    if (amount.indexOf(".") != -1) {
      console.log("in if");
      const int = amount.split(".");
      console.log("int", int);
      const value = insertZero(int[1], int[1].length);
      const tens = int[0].concat("000000000000000000");
      const total = parseInt(tens) + parseInt(value);
      console.log(total);
      // const web3 = new Web3(Web3.givenProvider);
      // const contractInstance = new web3.eth.Contract(
      //   idoABI,
      //   ido.contractAddress
      // );
      // console.log(contractInstance);
      // (token * 10 ** 18).toString()

      // const price = await contractInstance.methods.cost(total).call();
      // setpriceWillPay(price);
      return total.toString();
      // return int.concat("0000000000000000000");
    } else {
      const value = amount.concat("000000000000000000");
      console.log(value);
      // const web3 = new Web3(Web3.givenProvider);
      // const contractInstance = new web3.eth.Contract(
      //   idoABI,
      //   ido.contractAddress
      // );
      // console.log(contractInstance);
      // (token * 10 ** 18).toString()

      // const price = await contractInstance.methods.cost(value).call();
      // setpriceWillPay(price);
      return value;
    }
  };

  const entryInContract = async (hash) => {
    setinCreation(true);
    const web3 = new Web3(Web3.givenProvider);

    let contract = await new web3.eth.Contract(
      factorAbi,
      "0x956A6ac321d6c34B358dC70F04e3a26ed9DC14Ff"
    ); //TOKEN_IDO_ABI
    console.log(contract, "contract");

    const startDate = new Date(presaleInfo.startDate).getTime() / 1000;
    const endDate = new Date(presaleInfo.endDate).getTime() / 1000;

    console.log(startDate);
    console.log(endDate);

    const price = await handleAmount(presaleInfo.pricePerToken);
    const tokenForSale = await handleAmount(presaleInfo.tokensForSale);
    const minContribution = await handleAmount(presaleInfo.minContribute);
    const maxContribution = await handleAmount(presaleInfo.maxContribute);
    const minRaise = await handleAmount(presaleInfo.minimumRaise);
    // toWei(presaleInfo.tokensForSale),
    // addToBackend()
    const tx = await contract.methods
      .createIDO(
        hash,
        projectMetadata.tokenAddress,
        price,
        tokenForSale,
        startDate,
        endDate,
        minContribution,
        maxContribution,
        false,
        minRaise,
        presaleInfo.feePercent,
        true
      )
      .send({ from: wallet.account });
    console.log(tx, "transaction");
    addToBackend(tx.events.IdoCreated.returnValues[0]);
  };

  const addToBackend = async (contractAddress) => {
    await createIdo({
      variables: {
        productLogo: "", /// projectMetadata.logo
        productName: projectMetadata.name,
        description: JSON.stringify(descriptions),
        price: presaleInfo.pricePerToken,
        tokenAddress: projectMetadata.tokenAddress,
        images: JSON.stringify(presaleInfo),
        contractAddress: contractAddress, //this should be done after deployment
        vestingSchedule: "",
        status: "true",
        listed: true,
        metaData: JSON.stringify(projectMetadata),
      },
    });

    setTimeout(() => {
      window.location.pathname = "/dashboard/idos";
    }, 2000);
  };

  const UploadFile = async () => {
    const obj = {
      presaleInfo,
      projectMetadata,
      descriptions,
    };
    if (
      isAdmin &&
      projectMetadata.tokenAddress &&
      projectMetadata.name &&
      presaleInfo.feePercent &&
      presaleInfo.minContribute &&
      presaleInfo.maxContribute &&
      presaleInfo.pricePerToken &&
      presaleInfo.startDate &&
      presaleInfo.endDate &&
      presaleInfo.minimumRaise &&
      presaleInfo.tokensForSale
    ) {
      const readyJson = JSON.stringify(obj);
      console.log(readyJson);
      const file = Buffer.from(readyJson);
      const { path } = await ipfsClient.add(file);
      console.log(path);
      entryInContract(path);
    } else {
      if (projectMetadata.tokenAddress === "") {
        toast("Please enter token address");
      } else if (tokenName) {
        toast("Please enter valid token address");
      } else if (projectMetadata.name === "") {
        toast("Please enter name");
      } else if (presaleInfo.startDate === "") {
        toast("Please enter start date and time");
      } else if (presaleInfo.endDate === "") {
        toast("Please enter end date");
      } else if (presaleInfo.minimumRaise === "") {
        toast("Please enter minimum raise");
      } else if (presaleInfo.tokensForSale === "") {
        toast("Please enter token for sale");
      } else if (presaleInfo.feePercent === "") {
        toast("Please enter fee percent");
      } else if (presaleInfo.minContribute === "") {
        toast("Please enter Minimum Contribution");
      } else if (presaleInfo.maxContribute === "") {
        toast("Please enter Max Contribution");
      } else if (presaleInfo.pricePerToken === "") {
        toast("Please enter price per token");
      } else {
        toast("Please fill all details");
      }
      // switch(){
      // }
      if (tokenName == "") {
        toast("Please enter valid address");
      } else {
        toast("Please Fill all required details");
      }
    }
  };

  const [tokenName, settokenName] = useState("");
  const [tokenDecimals, settokenDecimals] = useState("");

  // console.log(entryInContract(),"log");

  const validity = async (token) => {
    const web3 = new Web3(Web3.givenProvider);

    try {
      let contract = await new web3.eth.Contract(tokenAbi, token); //TOKEN_IDO_ABI
      console.log(contract, "contract");
      if (contract) {
        const name = await contract.methods.name().call();
        const decimal = await contract.methods.decimals().call();
        console.log(name, decimal);
        await settokenName(name);
        await settokenDecimals(decimal);
      } else {
        toast("Please enter a valid Contract Address");
        setProjectMetadata({ ...projectMetadata, tokenAddress: "" });
      }
    } catch (err) {
      console.log("err");
      toast("Please enter a valid Contract Address");
    }
  };

  return (
    <Page title="Create IDO">
      {isAdmin && (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Create IDOS
            </Typography>
          </Stack>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Project Info
            </Typography>
          </Divider>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <ImageInput
              title="Choose Project Logo"
              onChange={(img) => {
                setProjectMetadata({
                  ...projectMetadata,
                  logo: img,
                });
              }}
              width={150}
              height={150}
            />

            <ImageInput
              title="Choose Project Header"
              onChange={(img) => {
                setProjectMetadata({
                  ...projectMetadata,
                  headerImage: img,
                });
              }}
            />
          </Stack>

          <Stack
            mb={5}
            mt={5}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Project Name"
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  name: e.target.value,
                });
              }}
            />

            <TextField
              fullWidth
              minRows={10}
              label="Project Token Address"
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  tokenAddress: e.target.value,
                });
                validity(e.target.value);
              }}
            />

            {/* <TextField fullWidth value={tokenName} disabled />
          <TextField fullWidth value={tokenDecimals} disabled /> */}
          </Stack>
          <Stack
            mb={5}
            mt={5}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField fullWidth value={tokenName} disabled />
            <TextField fullWidth value={tokenDecimals} disabled />
          </Stack>

          <Stack
            mb={5}
            mt={5}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Facebook"
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  socialMedias: {
                    ...projectMetadata.socialMedias,
                    facebook: e.target.value,
                  },
                });
              }}
            />

            <TextField
              fullWidth
              label="Telegram"
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  socialMedias: {
                    ...projectMetadata.socialMedias,
                    telegram: e.target.value,
                  },
                });
              }}
            />

            <TextField
              fullWidth
              label="Medium"
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  socialMedias: {
                    ...projectMetadata.socialMedias,
                    medium: e.target.value,
                  },
                });
              }}
            />

            <TextField
              fullWidth
              label="Twitter"
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  socialMedias: {
                    ...projectMetadata.socialMedias,
                    twitter: e.target.value,
                  },
                });
              }}
            />
          </Stack>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Descriptions
            </Typography>
          </Divider>

          <Stack>
            <DescriptionsInput
              title={"Product"}
              onImageChange={(img) => {
                setDescriptions({
                  ...descriptions,
                  product: {
                    ...descriptions.product,
                    img: img,
                  },
                });
              }}
              onHeadingChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  product: {
                    ...descriptions.product,
                    heading: text,
                  },
                });
              }}
              onContentChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  product: {
                    ...descriptions.product,
                    description: text,
                  },
                });
              }}
            />

            <DescriptionsInput
              title={"Problem"}
              onImageChange={(img) => {
                setDescriptions({
                  ...descriptions,
                  problem: {
                    ...descriptions.problem,
                    img: img,
                  },
                });
              }}
              onHeadingChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  problem: {
                    ...descriptions.problem,
                    heading: text,
                  },
                });
              }}
              onContentChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  problem: {
                    ...descriptions.problem,
                    description: text,
                  },
                });
              }}
            />

            <DescriptionsInput
              title={"Solution"}
              onImageChange={(img) => {
                setDescriptions({
                  ...descriptions,
                  solution: {
                    ...descriptions.solution,
                    img: img,
                  },
                });
              }}
              onHeadingChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  solution: {
                    ...descriptions.solution,
                    heading: text,
                  },
                });
              }}
              onContentChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  solution: {
                    ...descriptions.solution,
                    description: text,
                  },
                });
              }}
            />
            <DescriptionsInput
              title={"BusinessModal"}
              onImageChange={(img) => {
                setDescriptions({
                  ...descriptions,
                  businessModal: {
                    ...descriptions.solution,
                    img: img,
                  },
                });
              }}
              onHeadingChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  businessModal: {
                    ...descriptions.solution,
                    heading: text,
                  },
                });
              }}
              onContentChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  businessModal: {
                    ...descriptions.solution,
                    description: text,
                  },
                });
              }}
            />
            <DescriptionsInput
              title={"Market"}
              onImageChange={(img) => {
                setDescriptions({
                  ...descriptions,
                  market: {
                    ...descriptions.solution,
                    img: img,
                  },
                });
              }}
              onHeadingChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  market: {
                    ...descriptions.solution,
                    heading: text,
                  },
                });
              }}
              onContentChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  market: {
                    ...descriptions.solution,
                    description: text,
                  },
                });
              }}
            />
            <DescriptionsInput
              title={"Team"}
              onImageChange={(img) => {
                setDescriptions({
                  ...descriptions,
                  team: {
                    ...descriptions.solution,
                    img: img,
                  },
                });
              }}
              onHeadingChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  team: {
                    ...descriptions.solution,
                    heading: text,
                  },
                });
              }}
              onContentChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  team: {
                    ...descriptions.solution,
                    description: text,
                  },
                });
              }}
            />
            <DescriptionsInput
              title={"Investors"}
              onImageChange={(img) => {
                setDescriptions({
                  ...descriptions,
                  investor: {
                    ...descriptions.solution,
                    img: img,
                  },
                });
              }}
              onHeadingChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  investor: {
                    ...descriptions.solution,
                    heading: text,
                  },
                });
              }}
              onContentChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  investor: {
                    ...descriptions.solution,
                    description: text,
                  },
                });
              }}
            />
            <DescriptionsInput
              title={"Utility"}
              onImageChange={(img) => {
                setDescriptions({
                  ...descriptions,
                  utility: {
                    ...descriptions.solution,
                    img: img,
                  },
                });
              }}
              onHeadingChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  utility: {
                    ...descriptions.solution,
                    heading: text,
                  },
                });
              }}
              onContentChange={(text) => {
                setDescriptions({
                  ...descriptions,
                  utility: {
                    ...descriptions.solution,
                    description: text,
                  },
                });
              }}
            />
          </Stack>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              IDO Information
            </Typography>
          </Divider>

          {/* <Stack
          mb={5}
          mt={5}
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
        >
          <TextField
            fullWidth
            label="Token Address"
            onChange={(e) => {
              setPresaleInfo({...presaleInfo,tokenAddress:e.target.value})
            }}
          />
        </Stack> */}

          <Stack
            mb={5}
            mt={5}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <label style={{ width: "100%" }}>
              Start Date
              <Input
                type="datetime-local"
                fullWidth
                label="Start Date"
                placeholder="Start Date"
                onChange={(e) => {
                  setPresaleInfo({ ...presaleInfo, startDate: e.target.value });
                }}
                id="startDate"
              />
            </label>

            <label style={{ width: "100%" }}>
              End Date
              <Input
                fullWidth
                label="End Date"
                type="datetime-local"
                onChange={(e) => {
                  setPresaleInfo({ ...presaleInfo, endDate: e.target.value });
                }}
                id="endDate"
              />
            </label>
          </Stack>
          <Stack
            mb={5}
            mt={5}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              // min={moment().format("YYYY-MM-DDThh:mm:ss")}
              label="Price"
              placeholder="Price per token"
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  alert("Please enter number only");
                }
                setPresaleInfo({
                  ...presaleInfo,
                  pricePerToken: !isNaN(e.target.value)
                    ? e.target.value
                    : presaleInfo.pricePerToken,
                });
              }}
              // id="startDate"
            />

            <TextField
              fullWidth
              label="No. of IDO Token for sale"
              onChange={(e) => {
                console.log(!isNaN(e.target.value));
                if (isNaN(e.target.value)) {
                  alert("Please enter number only");
                }
                setPresaleInfo({
                  ...presaleInfo,
                  tokensForSale: !isNaN(e.target.value)
                    ? e.target.value
                    : presaleInfo.tokensForSale,
                });
              }}
              value={presaleInfo.tokensForSale}
            />
          </Stack>
          <Stack
            mb={5}
            mt={5}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Individual Minimum Amount in IDO token"
              value={presaleInfo.minContribute}
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  alert("Please enter number only");
                }
                setPresaleInfo({
                  ...presaleInfo,
                  minContribute: !isNaN(e.target.value)
                    ? e.target.value
                    : presaleInfo.minContribute,
                });
              }}
              min={moment(presaleInfo.startDate).format("YYYY-MM-DD")}
              id="endDate"
            />

            <TextField
              fullWidth
              value={presaleInfo.maxContribute}
              label="Individual Maximum Amount in IDO token"
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  alert("Please enter number only");
                }
                setPresaleInfo({
                  ...presaleInfo,
                  maxContribute: !isNaN(e.target.value)
                    ? e.target.value
                    : presaleInfo.maxContribute,
                });
              }}
            />
          </Stack>

          <Stack
            mb={5}
            mt={5}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Minimum Raise in BNB"
              value={presaleInfo.minimumRaise}
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  alert("Please enter number only");
                }
                setPresaleInfo({
                  ...presaleInfo,
                  minimumRaise: !isNaN(e.target.value)
                    ? e.target.value
                    : presaleInfo.minimumRaise,
                });
              }}
            />

            <TextField
              fullWidth
              label="Fee Amount in Percent 1 == 1%"
              value={presaleInfo.feePercent}
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  alert("Please enter number only");
                }
                if (e.target.value < 100) {
                  setPresaleInfo({
                    ...presaleInfo,
                    feePercent: !isNaN(e.target.value)
                      ? e.target.value
                      : presaleInfo.feePercent,
                  });
                } else {
                  alert("Enter amount should be less than 100");
                }
              }}
            />
          </Stack>

          {/* <Stack
          mb={5}
          mt={5}
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
        >
          <TextField
            fullWidth
            label="Minimum Raise"
            onChange={(e) => {
              setProjectMetadata({
                ...projectMetadata,
                socialMedias: {
                  ...projectMetadata.socialMedias,
                  medium: e.target.value,
                },
              });
            }}
          />


          <TextField
            fullWidth
            label="Fee Amount"
            onChange={(e) => {
              setProjectMetadata({
                ...projectMetadata,
                socialMedias: {
                  ...projectMetadata.socialMedias,
                  medium: e.target.value,
                },
              });
            }}
          />


        </Stack> */}
          {renderButton()}
          {/* { <Button variant="contained" onClick={() => {
          UploadFile()
        }}>
          Create Ido
        </Button>} */}
        </Container>
      )}
      {!isAdmin && (
        <div>
          Not authorized
          <button
            onClick={() => {
              wallet.connect();
            }}
          >
            Connect
          </button>
        </div>
      )}
    </Page>
  );
}
