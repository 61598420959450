// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { UseWalletProvider } from 'use-wallet';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import BigNumber from 'bignumber.js';

// ----------------------------------------------------------------------

const client = new ApolloClient({
  uri: "https://polkastarterdb.herokuapp.com/graphql",
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  },
})




BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

ReactDOM.render(
  <HelmetProvider>
    <ApolloProvider client={client}>
      <UseWalletProvider
        chainId={97}
        connectors={{
          // This is how connectors get configured
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </UseWalletProvider>
    </ApolloProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
