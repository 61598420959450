import { Button, Card, Container, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Page from 'src/components/Page'
import Scrollbar from 'src/components/Scrollbar'

import { CSVLink, CSVDownload } from "react-csv";

function WhiteList(props) {


   

    const renderCsvButton = (data) => {
        return (
            <CSVLink data={data}>
                Download
            </CSVLink>
        )
    }


    return (
        <div>
            <Page>
                <Container>
                    <Button
                        variant="contained"
                    >
                        { renderCsvButton(props.data)}
                    </Button>
                    <Card>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800, padding: "20px" }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ fontSize: "18px", fontWeight: "bold" }}>Id</TableCell>
                                            <TableCell component="th" scope="row" style={{ fontSize: "18px", fontWeight: "bold" }}>Ido Id</TableCell>
                                            <TableCell component="th" scope="row" style={{ fontSize: "18px", fontWeight: "bold" }}>Wallet Address</TableCell>
                                        </TableRow>
                                        {props.data && props.data.map((e) => {
                                            return (
                                                <TableRow style={{ padding: "10px", lineHeight: "2rem" }}>
                                                    <TableCell component="th" scope="row"  >{e.id}</TableCell>
                                                    <TableCell component="th" scope="row"  >{e.ido}</TableCell>
                                                    <TableCell component="th" scope="row"  >{e.contractAddress}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </Card>
                </Container>
            </Page>
        </div>
    )
}

export default WhiteList