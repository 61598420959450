import { Interface } from "@ethersproject/abi";
import Web3 from "web3";
import multicall from './utils/multicall.json';

const multicalls = async (abi,calls) => {
    const web3 = new Web3(Web3.givenProvider);

  const multi = new web3.eth.Contract(multicall, "0x67ADCB4dF3931b0C5Da724058ADC2174a9844412")
  const itf = new Interface(abi)

  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
  const { returnData } = await multi.methods.aggregate(calldata).call()
  const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  return res
}

export default multicalls
