import React, { useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'
import Web3 from 'web3';
import multicall from '../pages/utils/idofactory.json'

function useIsAdmin() {

    const wallet = useWallet();

    let status = false

    const [ownerAddress, setownerAddress] = useState("");

    useEffect(async () => {


        const web3 = new Web3(Web3.givenProvider);

        const multi = new web3.eth.Contract(multicall, "0x956A6ac321d6c34B358dC70F04e3a26ed9DC14Ff");
        console.log(multi, "loggss");

        const owner = await multi.methods.owner().call();
        const propsedOwner = await multi.methods.proposedOwner().call();

        // const 

        console.log(propsedOwner,"proposed owner",owner);
        await setownerAddress(owner);
        console.log(wallet);
        if (wallet.status === "connected" && wallet.account == owner || wallet.account == propsedOwner) {
            status = true;
        } else {
            status = false;
        }

    }, [wallet.account, wallet.status])

    return wallet.status === "connected" && wallet.account == ownerAddress;

}

export default useIsAdmin